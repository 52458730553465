import React from 'react'

const SeoMain = () => {
    return (
        <>
            <div className="app-development">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-3 aos-init aos-animate" data-aos="fade-right">
                            <div className="app-development-title">
                                The Best SEO Company in India
                            </div>
                            <p>In the increasing competitive world of the businesses, the need and the importance of the companies engaged in rendering the valuable search engine optimization services has increased considerably. There is a very strong and healthy competition and hence it is necessary to go for the best SEO Company in India if you are looking to stay ahead in the race.</p>
                            <p>Search Engine Optimization (SEO) is a process that optimizes your website and generates free traffic from search engines. It is the most common method that improves the ranking of your web page. Whereas Search engine marketing (SEM) is recognized as one of the most effective tools that help to grow your business in India or other parts of the world. We at Noble Webtech have the proven industry experience and have been delivering the effective results from years as a dependable SEO Company in Delhi. With us you are sure to achieve the desired business goal in the shortest period of time.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SeoMain