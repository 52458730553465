import React from 'react'
import Banner from './Banner/Banner'
import ServiceWeOffer from './HomeServices/ServiceWeOffer'
import KeyFeature from './KeyFeature/KeyFeature'
import Process from './Process/Process'
import PartnerShip from './Partnership/PartnerShip'
import Facts from './Facts/Facts'
import Blog from './BlogSec/Blog'
import CreateStory from './CreateStory/CreateStory'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

const Home = () => {
  return (
    <>
        <Header></Header>
        <Banner></Banner>
        <ServiceWeOffer></ServiceWeOffer>
        <KeyFeature></KeyFeature>
        <Process></Process>
        <PartnerShip></PartnerShip>
        <Facts></Facts>
        <Blog></Blog>
        <CreateStory></CreateStory>
        <Footer></Footer>
    </>
  )
}

export default Home

