import React from 'react'

const SmmServices = () => {
  return (
    <>
        <div className="your-business">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="topic"> Smm Features </div>
                            <h4 className='text-white'>Advantages of Noble Webtech SMM Services in India:</h4>
                            <div className="sub-topic">Social Media Marketing (SMM) is an excellent way of internet promotion to increase the market reach. The importance of the Social Media Marketing (SMM) is unlimited. It completely depends upon how it is used and by whom it is used. There is no doubt in the fact that for this important purpose, the expertise of the best SMO Company in India is essential.  </div>
                        </div>
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-7 aos-init aos-animate" data-aos="fade-right">
                            <div className="tab-container">
                                <div className='row'>
                                    <p>On-demand taxi apps usually consist of two separate in-app roles or apps: Driver’s and Passenger’s. Beside basic functionality, taxi apps require map APIs (Google Maps, MapKit), payment gateways (Stripe, PayPal) and in-app calling feature integration (Twilio, Bandwith).</p>
                                    <div className="col-md-4 pl-0 pr-5">
                                        <h5>2200 hours</h5>
                                        <small>Average development time</small>
                                    </div>
                                    <div className="col-md-4 pl-0 pr-5">
                                        <h5>6 months</h5>
                                        <small>Average project length</small>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <div className="btn"> See Case Study </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default SmmServices