import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import CmarketingBanner from './CmarketingBanner'
import CMarketingMain from './CMarketingMain'
import CMarketingServices from './CMarketingServices'
import ProcessWeFollow from '../ProcessWeFollow'

const CMarketingDevelopment = () => {
  return (
    <>  
        
        <Header></Header>
        <CmarketingBanner></CmarketingBanner>
        <CMarketingMain></CMarketingMain>
        <CMarketingServices></CMarketingServices>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default CMarketingDevelopment