import React from 'react'

const PartnerShip = () => {
    return (
        <>
            <section className="partnership-models">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="partnership-title text-center" data-aos="fade-up">
                                <h3>Partnership Models</h3>
                                <p>Hyperlink InfoSystem a leading mobile app development company in USA &amp; India offers custom app development services to wide range of industries and businesses. Know more about our partnership models.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3" data-aos="fade-right">
                            <div className="partnership-discription">
                                <h5>Fixed Price Model</h5>
                                <p>In this model, the project scope of work with its associated cost and timeline is defined before development starts. This is a preferred model for longer periods of engagement. Client always has peace of mind of knowing the project will remain on the same budget as agreed. This model suits best to clients who have a perfect vision of their requirement.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3" data-aos="fade-up">
                            <div className="partnership-discription">
                                <h5>Hire Dedicated Model</h5>
                                <p>In this model, the project scope of work with its associated cost and timeline is defined before development starts. This is a preferred model for longer periods of engagement. Client always has peace of mind of knowing the project will remain on the same budget as agreed. This model suits best to clients who have a perfect vision of their requirement.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3" data-aos="fade-left">
                            <div className="partnership-discription">
                                <h5>On Site Development Model</h5>
                                <p>In this model, the project scope of work with its associated cost and timeline is defined before development starts. This is a preferred model for longer periods of engagement. Client always has peace of mind of knowing the project will remain on the same budget as agreed. This model suits best to clients who have a perfect vision of their requirement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PartnerShip