import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import WebBanner from './WebBanner'
import WebsiteMain from './WebsiteMain'
import ProcessWeFollow from '../ProcessWeFollow'
import WebServices from './WebServices'

const WebDevelopment = () => {
  return (
    <>  
        
        <Header></Header>
        <WebBanner></WebBanner>
        <WebsiteMain></WebsiteMain>
        <WebServices></WebServices>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default WebDevelopment