import React from 'react'
import Iospic from '../../../images/iosapp.png'
const IosBanner = () => {
    return (
        <>
            <div className="email-services-banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">                       
                        <div className="android-app-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <img src={Iospic} alt="IOS Banner" style={{width: "auto"}} className="shake" />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="php-web-app">
                                            <h2>ios App Deployment</h2>
                                            <h5>We would be happy to hear from you, Please fill in the form below or mail us
                                                your requirements on</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default IosBanner