import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SeoBanner from './SeoBanner'
import SeoMain from './SeoMain'
import SeoServices from './SeoServices'
import ProcessWeFollow from '../ProcessWeFollow'

const SeoDevelopment = () => {
  return (
    <>  
        
        <Header></Header>
        <SeoBanner></SeoBanner>
        <SeoMain></SeoMain>
        <SeoServices></SeoServices>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default SeoDevelopment