import React from 'react'
import B1 from '../../../images/smo.jpg'
import B2 from '../../../images/seo.jpg'
import B3 from '../../../images/digital-marketing.avif'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import BlogBanner from './BlogBanner'
import { NavLink } from 'react-router-dom'

const BlogMain = () => {
    return (
        <>
            <Header />
            <BlogBanner />
            <section className="section p-t80-b60 blog-section index-blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 m-b-30" data-aos="fade-right">
                            <div className="blog-content shadow-box">
                                <NavLink to={'/blogdetails'}>
                                <img src={B1} alt="B1" className="post-img" />
                                <div className="post-content block-six">
                                    <div className="post-time"><span className="big">25</span><span className="small">September 2020<span className="hidden-xs"></span></span>
                                    </div>
                                    <div className="post-title">
                                        <h3 className="f-w-600 f-s-18">Which Video Streaming Service Should You Choose: Netflix Vs. Amazon Prime</h3>
                                    </div>
                                </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 m-b-30" data-aos="fade-up">
                            <div className="blog-content shadow-box">
                                <img src={B2} alt="" className="post-img" />
                                <div className="post-content block-six">
                                    <div className="post-time"><span className="big">24</span><span className="small">September 2020<span className="hidden-xs"></span></span>
                                    </div>
                                    <div className="post-title">
                                        <h3 className="f-w-600 f-s-18">Mobile Xbox App(Beta) To Keep You Connected With Games And Friends!</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 m-b-30" data-aos="fade-left">
                            <div className="blog-content shadow-box">
                                <img src={B3} alt="" className="post-img" />
                                <div className="post-content block-six">
                                    <div className="post-time"><span className="big">23</span><span className="small">September 2020<span className="hidden-xs"></span></span>
                                    </div>
                                    <div className="post-title">
                                        <h3 className="f-w-600 f-s-18">The Future of Mobile App Development: Multi Experience Development Platform</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BlogMain