import React from 'react'
import AppPic from '../../../images/car-mobile-app.png'


const CommingApp = () => {
  return (
    <>
        <div className="app-cost">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
        <div className="inner">
          <h4> Comming Soon </h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>	
          <div className="mt-4">
            <div className="btn-style">Contact Us</div>
          </div>
        </div>	
      </div>
      <div className="col-md-8 aos-init aos-animate" data-aos="fade-up">
        <img src={AppPic} alt='AppPic' />
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default CommingApp