import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SmmBanner from './SmmBanner'
import SmmMain from './SmmMain'
import SmmServices from './SmmServices'
import ProcessWeFollow from '../ProcessWeFollow'

const SmmDevelopment = () => {
  return (
    <>  
        
        <Header></Header>
        <SmmBanner></SmmBanner>
        <SmmMain></SmmMain>
        <SmmServices></SmmServices>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default SmmDevelopment