import React from 'react'

const ServiceBanner = () => {
  return (
    <>
        <div className="banner-services">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center mb-5">
                        <div className="email-services-inner">
                            <h2 data-animation="animated zoomInLeft">Our Services</h2>
                            <h5>We design and develop Web for All</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ServiceBanner