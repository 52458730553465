import React from 'react'
import Ios1 from '../../../images/img-phone-ios.png'
import Ios2 from '../../../images/img-phone-android.png'
const IosMain = () => {
    return (
        <>
            <div className="app-development">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-5 aos-init aos-animate" data-aos="fade-right">
                            <div className="app-development-title">
                                Full-cycle iOS and Android app development
                            </div>
                            <div className="app-development-sub-title pt-3">
                                Efficient solutions to fit your business domain and budget requirements. We provide custom
                                development for businesses, MVPs to verify your startup ideas and launch your product earlier,
                                and development teams to support or enhance existing products.
                            </div>
                        </div>
                        <div className="col-md-3 aos-init" data-aos="fade-up">
                            <img src={Ios1} alt="iOS app development" />
                            <h6 className="mt-4 mb-3"> iOS app development </h6>
                            <p>Fully functional applications for all Apple devices</p>
                        </div>
                        <div className="col-md-3 aos-init" data-aos="fade-up">
                            <img src={Ios2} alt='Android app development' />
                            <h6 className="mt-4 mb-3">Android app development</h6>
                            <p>Sophisticated applications for Android-powered devices</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default IosMain