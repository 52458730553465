import React from 'react'
import { NavLink } from 'react-router-dom'
import FooterMap from './FooterMap'
import FooterTop from './FooterTop'

const Footer = () => {
    return (
        <>
            <FooterTop></FooterTop>
            <FooterMap></FooterMap>

            <div className="py-2 footer-bottom">
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <ul className='text-uppercase'>     
                                <li>
                                    <NavLink to={`/about`}>about US</NavLink>
                                </li> |
                                <li>
                                    <NavLink to={`/contact`}>Contact Us</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <p className="text-white"> COPYRIGHT©️ NOBLE WEBTECH</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer