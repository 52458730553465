import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import PhpBanner from './PhpBanner'
import PhpMain from './PhpMain'
import PhpServices from './PhpServices'
import ProcessWeFollow from '../ProcessWeFollow'

const PhpDevelopment = () => {
  return (
    <>  
        
        <Header></Header>
        <PhpBanner></PhpBanner>
        <PhpMain></PhpMain>
        <PhpServices></PhpServices>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default PhpDevelopment