import React from 'react'
import Mobile from '../../../images/mobile-view.jpeg'

const KeyFeature = () => {
  return (
    <>
      <section className="section Features-section desktop-feature mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading text-center" data-aos="fade-left">
                <h2 className="section-name">Our Key Features</h2>
                <p className="pt-0 pb-4">Services That We Deliver Makes Us Dignified As One of The Top App Development Companies</p>
              </div>
            </div>
            <div className="col-md-3">
              <img src={Mobile} alt='Mobile View'></img>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-lg-6 col-md-6 key-block-info-left">
                  <div className="key-block shadow-box block-seven green-light-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 498.752 498.752" style={{ enableBackground: 'new 0 0 498.752 498.752' }} xmlSpace="preserve" width="40px" height="40px" fill="#a4c639">
                        <g>
                          <g>
                            <path d="M480.12,435.248c-6.088-20.272-22.8-35.216-43.624-39L384,386.696v-4.144c19.632-11.4,34.008-30.848,38.488-53.84
				  C437.032,325.704,448,312.8,448,297.376V218.36c0-22.328-14.624-41.8-35.84-48.136l-4-4
				  c-18.608-18.608-43.344-28.848-69.656-28.848c-54.312,0-98.504,44.184-98.504,98.504v61.496c0,15.424,10.968,28.328,25.512,31.336
				  C270,351.704,284.368,371.16,304,382.552v4.144l-52.496,9.544c-20.824,3.792-37.536,18.736-43.624,39.008l-18.632,62.128h309.504
				  L480.12,435.248z M424,311.152V283.6c4.76,2.776,8,7.88,8,13.776S428.76,308.376,424,311.152z M264,311.152
				  c-4.76-2.776-8-7.88-8-13.776s3.24-11,8-13.776V311.152z M264,257.376v9.136c-2.848,0.744-5.52,1.864-8,3.312V235.88
				  c0-45.488,37.008-82.504,82.504-82.504c22.032,0,42.76,8.584,58.344,24.168l7.056,7.064l2.152,0.528
				  c15.28,3.816,25.944,17.48,25.944,33.224v51.464c-2.48-1.448-5.152-2.576-8-3.312v-9.136h-25.368
				  c-21.68,0-42.4-10.352-55.4-27.688l-6.36-8.496l-15.896,15.904c-13.088,13.08-30.48,20.28-48.976,20.28H264z M280,313.376v-40.368
				  c19.752-1.832,38.08-10.4,52.288-24.6l3.112-3.112c16.04,17.704,39.144,28.08,63.232,28.08H408v40c0,35.288-28.712,64-64,64
				  C308.712,377.376,280,348.664,280,313.376z M320.68,389.888c7.384,2.256,15.208,3.488,23.32,3.488s15.936-1.232,23.32-3.488
				  L344,407.376L320.68,389.888z M331.824,418.24l-16.328,16.32l-10.592-31.768l5.096-0.92L331.824,418.24z M378,401.872l5.088,0.928
				  l-10.592,31.768l-16.328-16.32L378,401.872z M210.752,481.376l12.464-41.528c4.344-14.48,16.28-25.16,31.16-27.856l34.64-6.296
				  l19.496,58.496L344,428.688l35.496,35.496l19.496-58.496l34.64,6.296c14.872,2.704,26.816,13.384,31.16,27.856l12.456,41.536
				  H210.752z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M208,130.728V80.024l-21.712-5.784c-0.64-1.672-1.336-3.344-2.096-5.016l11.272-19.472l-35.84-35.84l-19.472,11.272
				c-1.664-0.76-3.336-1.456-5-2.096l-5.808-21.712H78.656l-5.8,21.72c-1.672,0.64-3.336,1.336-5,2.096l-19.472-11.28l-35.84,35.84
				l11.272,19.472c-0.768,1.672-1.464,3.344-2.104,5.016L0,80.024v50.704l21.712,5.784c0.64,1.672,1.336,3.344,2.104,5.016
				L12.544,161l35.84,35.84l19.472-11.272c1.664,0.76,3.336,1.456,5,2.096l5.8,21.712h50.688l5.8-21.72
				c1.672-0.64,3.336-1.336,5-2.096l19.472,11.272l35.84-35.84l-11.272-19.472c0.76-1.672,1.464-3.344,2.096-5.016L208,130.728z
				M175.456,158.368l-18.464,18.472l-16.28-9.432l-3.888,1.992c-3.568,1.832-7.2,3.352-10.784,4.512l-4.152,1.336l-4.832,18.128
				H90.944l-4.832-18.128l-4.152-1.336c-3.584-1.168-7.216-2.68-10.784-4.512l-3.888-1.992l-16.28,9.432l-18.464-18.472l9.424-16.272
				l-1.992-3.888c-1.848-3.592-3.36-7.224-4.504-10.784l-1.336-4.16L16,118.432V92.32l18.136-4.832l1.336-4.16
				c1.144-3.552,2.656-7.184,4.504-10.784l1.992-3.888l-9.424-16.272l18.464-18.472l16.28,9.432l3.888-1.992
				c3.568-1.832,7.2-3.352,10.784-4.512l4.152-1.336l4.832-18.128h26.112l4.832,18.128l4.152,1.336
				c3.584,1.168,7.216,2.68,10.784,4.512l3.888,1.992l16.28-9.432l18.464,18.472l-9.424,16.272l1.992,3.888
				c1.848,3.592,3.36,7.224,4.504,10.784l1.336,4.16L192,92.32v26.112l-18.136,4.832l-1.336,4.16
				c-1.144,3.552-2.656,7.184-4.504,10.784l-1.992,3.888L175.456,158.368z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M148.944,138.792C156.176,129.08,160,117.528,160,105.376c0-30.872-25.12-56-56-56s-56,25.128-56,56s25.12,56,56,56
			  c12.48,0,24.28-4.008,34.136-11.6l-9.76-12.672c-7.04,5.416-15.464,8.272-24.376,8.272c-22.056,0-40-17.944-40-40
			  c0-22.056,17.944-40,40-40c22.056,0,40,17.944,40,40c0,8.68-2.728,16.928-7.888,23.856L148.944,138.792z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M482.344,99.72L472,110.064V81.376c0-44.112-35.888-80-80-80h-48v16h48c35.288,0,64,28.712,64,64v28.688L445.656,99.72
			l-11.312,11.312L464,140.688l29.656-29.656L482.344,99.72z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M40,417.376v-28.688l10.344,10.344l11.312-11.312L32,358.064L2.344,387.72l11.312,11.312L24,388.688v28.688
		  c0,44.112,35.888,80,80,80v-16C68.712,481.376,40,452.664,40,417.376z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <rect y="241.376" width={208} height={16} />
                          </g>
                        </g>
                        <g>
                          <g>
                            <rect y="305.376" width={208} height={16} />
                          </g>
                        </g>
                        <g>
                          <g>
                            <rect y="273.376" width={96} height={16} />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon points="158,273.376 120,273.376 112,273.376 112,289.376 120,289.376 158,289.376 208,289.376 208,273.376        " />
                          </g>
                        </g>
                        <g>
                          <g>
                            <rect x={312} y="1.376" width={16} height={16} />
                          </g>
                        </g>
                        <g>
                          <g>
                            <rect x={280} y="1.376" width={16} height={16} />
                          </g>
                        </g>
                        <g>
                          <g>
                            <rect x={120} y="481.376" width={16} height={16} />
                          </g>
                        </g>
                        <g>
                          <g>
                            <rect x={152} y="481.376" width={16} height={16} />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>1. Client Centric Development</h6>
                      <p>We are the one that leverages best ever web, mobile &amp; blockchain solutions to the clients by tailoring as per their business requirements.</p>
                    </div>
                  </div>
                  <div className="key-block shadow-box block-seven blue-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve" width="40px" height="40px" fill="#1094f1">
                        <g>
                          <g>
                            <path d="M256.604,67.16c-16.164,0-29.314,13.151-29.314,29.314c0,16.163,13.15,29.313,29.314,29.313s29.313-13.15,29.313-29.313
			  C285.916,80.31,272.768,67.16,256.604,67.16z M256.603,110.786c-7.892,0-14.313-6.42-14.313-14.312
			  c0-7.892,6.42-14.313,14.313-14.313c7.892,0.001,14.312,6.421,14.312,14.313C270.914,104.365,264.494,110.786,256.603,110.786z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M431.405,330.567c-16.164,0-29.314,13.15-29.314,29.314c0,16.163,13.15,29.313,29.314,29.313
			c16.164,0,29.314-13.15,29.314-29.313C460.719,343.717,447.569,330.567,431.405,330.567z M431.405,374.193
			c-7.893,0-14.313-6.42-14.313-14.312c0-7.892,6.42-14.313,14.313-14.313c7.892,0,14.313,6.42,14.313,14.313
			C445.718,367.772,439.298,374.193,431.405,374.193z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M80.595,330.567c-16.163,0-29.314,13.15-29.314,29.314c0,16.163,13.15,29.313,29.314,29.313
		  c16.164,0,29.314-13.15,29.314-29.313C109.908,343.717,96.758,330.567,80.595,330.567z M80.595,374.193
		  c-7.892,0-14.313-6.42-14.313-14.312c0-7.892,6.42-14.313,14.313-14.313c7.893,0,14.313,6.42,14.313,14.313
		  C94.907,367.772,88.486,374.193,80.595,374.193z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M450.696,309.45c-4.026-0.99-8.085,1.47-9.074,5.493c-0.988,4.022,1.471,8.084,5.493,9.074
		  c29.37,7.218,49.883,33.408,49.883,63.687c0,20.89-9.829,39.517-25.092,51.538v-21.157c0-13.454-10.945-24.398-24.398-24.398
		  h-32.206c-13.454,0-24.398,10.946-24.398,24.398v21.158c-15.263-12.021-25.093-30.648-25.093-51.539
		  c0-30.182,20.444-56.45,49.883-63.687c4.022-0.989,6.481-5.051,5.493-9.074c-0.988-4.022-5.05-6.483-9.074-5.493
		  c-7.416,1.823-14.364,4.644-20.736,8.275l-84.741-130.27c14.849-11.781,24.665-28.082,28.565-45.357
		  c0.911-4.041-1.625-8.055-5.665-8.968c-4.044-0.912-8.055,1.625-8.969,5.665c-3.375,14.96-11.919,27.842-23.461,36.931v-21.05
		  c0-13.454-10.945-24.398-24.398-24.398h-32.207c-13.454,0-24.398,10.946-24.398,24.398v21.157
		  c-15.263-12.021-25.092-30.648-25.092-51.538c0-36.169,29.426-65.594,65.594-65.594c29.687,0,55.748,20.015,63.379,48.672
		  c1.066,4.003,5.173,6.382,9.178,5.318c4.003-1.066,6.384-5.174,5.317-9.178c-9.377-35.217-41.398-59.814-77.874-59.814
		  c-44.441,0-80.595,36.154-80.595,80.595c0,25.464,11.884,48.192,30.381,62.972L120.822,317.91
		  c-11.849-6.855-25.581-10.802-40.226-10.802C36.154,307.109,0,343.263,0,387.703c0,44.41,36.178,80.595,80.595,80.595
		  c40.195,0,73.432-29.565,79.565-67.782H351.84c6.037,37.625,38.749,67.782,79.565,67.782c44.295,0,80.595-36.07,80.595-80.595
		  C512,350.5,486.791,318.321,450.696,309.45z M231.101,154.676L231.101,154.676c0.001-5.182,4.217-9.398,9.399-9.398h32.207
		  c5.182,0,9.398,4.216,9.398,9.398v29.953c-8.142,3.482-16.788,5.261-25.502,5.261c-9.04,0-17.658-1.838-25.502-5.16V154.676z
		  M256.604,284.96c8.025,0,14.556,6.529,14.556,14.556s-6.529,14.556-14.556,14.556s-14.556-6.529-14.556-14.556
		  S248.578,284.96,256.604,284.96z M55.094,448.136v-30.054c0-5.182,4.216-9.398,9.398-9.398h32.206
		  c5.182,0,9.398,4.216,9.398,9.398v30.054h0.001C89.856,455.016,71.358,455.026,55.094,448.136z M121.097,439.242v-21.157
		  c0-13.454-10.945-24.398-24.398-24.398H64.492c-13.454,0-24.398,10.946-24.398,24.398v21.158
		  c-15.264-12.022-25.093-30.648-25.093-51.539c0-36.169,29.426-65.594,65.594-65.594c36.168,0,65.594,29.425,65.594,65.594
		  C146.189,408.594,136.36,427.221,121.097,439.242z M133.061,326.603l85.863-131.091c9.293,4.939,19.241,8.034,30.179,9.037v66.379
		  c-12.677,3.328-22.056,14.883-22.056,28.59c0,1.709,0.154,3.382,0.434,5.012l-77.048,43.028
		  C145.854,339.623,139.967,332.541,133.061,326.603z M350.857,385.515H161.134c-0.228-8.482-1.766-16.639-4.43-24.278
		  l77.032-43.019c5.424,6.62,13.661,10.855,22.868,10.855c9.085,0,17.222-4.123,22.648-10.593l76.008,42.716
		  C352.598,368.874,351.083,377.055,350.857,385.515z M285.663,304.876c0.32-1.74,0.496-3.529,0.496-5.36
		  c0-13.707-9.38-25.262-22.056-28.59v-66.379c10.721-0.979,20.456-3.939,29.954-8.918l85.026,130.707
		  c-7.038,5.995-12.976,13.163-17.571,21.165L285.663,304.876z M456.906,448.136c-7.843,3.322-16.462,5.16-25.502,5.16
		  c-9.04,0-17.657-1.838-25.501-5.16v-30.053h0.001c0-5.182,4.216-9.398,9.398-9.398h32.206c5.182,0,9.398,4.216,9.398,9.398
		  V448.136z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>3. Dedicated Development Team</h6>
                      <p>We assign a cost-effective dedicated team to all our projects that not only assures quality but gives excellent support and satisfaction to our valuable clients.</p>
                    </div>
                  </div>
                  <div className="key-block shadow-box block-seven light-blue-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 486.86 486.86" style={{ enableBackground: 'new 0 0 486.86 486.86' }} xmlSpace="preserve" width="40px" height="40px" fill="#1d13ed">
                        <g>
                          <g>
                            <path d="M460.692,213.208c-7.798-7.659-16.943-13.813-26.976-18.152c-2.633-19.12-11.536-36.826-25.312-50.344
			  c-21.837-21.406-53.042-30.272-82.872-23.544c-4.502-10.474-10.983-19.982-19.088-28c-21.936-21.428-53.228-30.295-83.144-23.56
			  c-37.286,8.748-64.853,40.28-68.544,78.4c-16.161-12.106-38.735-10.626-53.176,3.488c-9.461,9.389-13.589,22.894-10.992,35.968
			  h-0.504c-41.685-0.444-78.155,27.956-87.936,68.48c-6.664,29.574,2.41,60.494,24,81.776c16.785,16.575,39.45,25.831,63.04,25.744
			  h17.064c4.418,0,8-3.582,8-8s-3.582-8-8-8H89.188c-19.36,0.064-37.959-7.535-51.736-21.136
			  c-17.687-17.425-25.128-42.745-19.68-66.968c8.146-33.199,38.132-56.378,72.312-55.896h11.936
			  c4.418,0.004,8.003-3.574,8.008-7.992c0.001-1.407-0.368-2.789-1.072-4.008c-5.321-9.299-3.733-21.013,3.872-28.56
			  c9.642-9.447,25.07-9.447,34.712,0l9.096,8.96c3.147,3.102,8.212,3.065,11.313-0.081c1.471-1.493,2.298-3.503,2.303-5.599v-8.44
			  c-0.409-34.365,23.019-64.438,56.44-72.448c24.636-5.6,50.431,1.667,68.52,19.304c8.11,8.031,14.221,17.855,17.84,28.68
			  c1.379,4.127,5.804,6.395,9.96,5.104c26.099-8.21,54.599-1.394,74.16,17.736c12.143,11.838,19.625,27.648,21.08,44.544
			  c-0.003,3.335,2.063,6.322,5.184,7.496c9.735,3.583,18.601,9.186,26.016,16.44c17.675,17.431,25.098,42.753,19.632,66.968
			  c-8.143,33.2-38.131,56.381-72.312,55.896h-18.52c-4.418,0-8,3.582-8,8s3.582,8,8,8h18.52
			  c41.686,0.449,78.159-27.948,87.944-68.472C491.375,265.411,482.292,234.488,460.692,213.208z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M242.252,211.464c-61.648,0-128,12.512-128,40v128c0,27.488,66.352,40,128,40s128-12.512,128-40v-128
			C370.252,223.976,303.9,211.464,242.252,211.464z M354.252,379.464c0,7.2-38.104,24-112,24s-112-16.8-112-24v-11.32
			c23.368,13.144,68.8,19.32,112,19.32s88.632-6.176,112-19.32V379.464z M354.252,347.464c0,7.2-38.104,24-112,24s-112-16.8-112-24
			v-11.32c23.368,13.144,68.8,19.32,112,19.32s88.632-6.176,112-19.32V347.464z M354.252,315.464c0,7.2-38.104,24-112,24
			s-112-16.8-112-24v-11.32c23.368,13.144,68.8,19.32,112,19.32s88.632-6.176,112-19.32V315.464z M354.252,283.464
			c0,7.2-38.104,24-112,24s-112-16.8-112-24v-11.32c23.368,13.144,68.8,19.32,112,19.32s88.632-6.176,112-19.32V283.464z
			M242.252,275.464c-73.896,0-112-16.8-112-24s38.104-24,112-24s112,16.8,112,24S316.148,275.464,242.252,275.464z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>5. Data Backups</h6>
                      <p>Regular backup has helped us to survive in most extreme conditions. We carefully maintain clients project backups to deal with misfortunes in the best possible manner.</p>
                    </div>
                  </div>
                  <div className="key-block shadow-box block-seven purple-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve" width="40px" height="40px" fill="#a241bb">
                        <g>
                          <g>
                            <path d="M442.491,241.824l-15.808-27.088c-2.08-3.564-6.655-4.766-10.22-2.687c-3.564,2.08-4.768,6.656-2.687,10.22l10.158,17.406
			  h-96.192l48.099-82.408l14.088,24.139c2.08,3.565,6.657,4.768,10.22,2.687c3.564-2.08,4.768-6.656,2.687-10.22l-19.712-33.777
			  c-1.845-3.161-5.266-5.126-8.927-5.126H137.804c-3.661,0-7.082,1.964-8.926,5.126L69.511,241.821
			  c-2.132,3.651-1.842,8.174,0.741,11.525l103.663,134.399c1.472,1.909,3.685,2.909,5.922,2.909c1.593,0,3.199-0.507,4.558-1.556
			  c3.268-2.52,3.873-7.213,1.353-10.481l-95.64-123.998h101.311l49.223,195.168l-26.057-33.784
			  c-2.519-3.266-7.213-3.873-10.481-1.352c-3.268,2.52-3.873,7.213-1.353,10.48l45.063,58.426c1.972,2.558,4.956,4.025,8.186,4.025
			  c3.23,0,6.213-1.467,8.185-4.024L441.75,253.345C444.332,249.995,444.622,245.472,442.491,241.824z M362.827,149.915
			  l-47.921,82.104l-46.136-82.104H362.827z M302.065,239.675h-92.13L256,157.696L302.065,239.675z M88.067,239.675l48.093-82.408
			  l48.099,82.408H88.067z M149.173,149.915h94.057l-46.136,82.104L149.173,149.915z M256,449.569l-49.168-194.95h98.336L256,449.569
			  z M271.358,449.789l49.223-195.169h101.311L271.358,449.789z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M256,24.418c-4.126,0-7.472,3.345-7.472,7.472v33.875c0,4.127,3.345,7.472,7.472,7.472s7.472-3.345,7.472-7.472V31.89
			C263.472,27.763,260.126,24.418,256,24.418z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M437.019,82.794c-2.918-2.917-7.649-2.917-10.567,0l-23.953,23.952c-2.918,2.919-2.918,7.649,0,10.568
		  c1.459,1.458,3.371,2.188,5.283,2.188c1.912,0,3.825-0.73,5.284-2.188l23.953-23.952
		  C439.937,90.443,439.937,85.713,437.019,82.794z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M504.528,256.34h-33.875c-4.127,0-7.472,3.345-7.472,7.472s3.346,7.472,7.472,7.472h33.875
		  c4.127,0,7.472-3.345,7.472-7.472C512,259.685,508.654,256.34,504.528,256.34z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M41.347,256.34H7.472c-4.127,0-7.472,3.345-7.472,7.472s3.346,7.472,7.472,7.472h33.875c4.127,0,7.472-3.345,7.472-7.472
		  C48.819,259.685,45.474,256.34,41.347,256.34z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M109.502,106.746L85.548,82.793c-2.918-2.917-7.649-2.917-10.567,0c-2.918,2.919-2.918,7.649,0,10.568l23.953,23.953
		  c1.459,1.458,3.371,2.188,5.284,2.188c1.913,0,3.825-0.73,5.284-2.188C112.42,114.395,112.42,109.665,109.502,106.746z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>7. Quality Deliverance</h6>
                      <p>We believe in delivering quality products to our clients by assuring all their project specification in the best industry competitive price.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 key-block-info-right">
                  <div className="key-block shadow-box block-seven yellow-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style={{ enableBackground: 'new 0 0 60 60' }} xmlSpace="preserve" width="40px" height="40px" fill="#ff9800">
                        <g>
                          <g>
                            <path d="M60,13c0-0.1,0-0.2-0.1-0.3v-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-12-12c-0.1-0.1-0.2-0.1-0.3-0.2h-0.1C47.2,0.1,47.1,0,47,0
			  l0,0H1C0.4,0,0,0.4,0,1v9h2V2h44v11c0,0.6,0.4,1,1,1h11v18h2V13L60,13z M48,12V3.4l8.6,8.6H48z" />
                            <path d="M59,42h-2.8c-0.1-0.1-0.1-0.3-0.2-0.4l2-1.8c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7l-4.2-4.2c-0.4-0.4-1-0.4-1.4,0
			  l-1.9,1.9c-0.2-0.1-0.3-0.1-0.4-0.2V33c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v2.7c-0.1,0.1-0.3,0.1-0.4,0.2L39.8,34
			  c-0.4-0.4-1-0.4-1.4,0l-4.2,4.2c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7l1.9,1.9c-0.1,0.2-0.1,0.3-0.2,0.4H33
			  c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h2.8c0.1,0.1,0.1,0.3,0.2,0.4l-1.9,1.9c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
			  l4.2,4.2c0.4,0.4,1,0.4,1.4,0l1.9-1.9c0.2,0.1,0.3,0.1,0.4,0.2V59c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-2.8
			  c0.1-0.1,0.3-0.1,0.4-0.2l1.9,1.9c0.4,0.4,1,0.4,1.4,0l4.2-4.2c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7L56,50.4
			  c0.1-0.1,0.1-0.3,0.2-0.4H59c0.6,0,1-0.4,1-1v-6C60,42.4,59.6,42,59,42z M58,48h-2.5c-0.5,0-0.9,0.4-1,0.8c0,0.2-0.4,1-0.5,1.2
			  s-0.2,0.4-0.2,0.6c0,0.3,0.1,0.5,0.3,0.7l1.7,1.7L53,55.8l-1.7-1.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.4,0.1-0.6,0.2
			  s-1,0.4-1.2,0.5c-0.5,0.1-0.8,0.5-0.8,1V58h-4v-2.5c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.3-1.9-1-2.3-1c-0.3,0-0.5,0.1-0.7,0.3L39,55.8
			  L36.2,53l1.7-1.7c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2-0.1-0.4-0.2-0.6s-0.4-1-0.5-1.2c-0.1-0.5-0.5-0.8-1-0.8H34v-4h2.5
			  c0.3,0,0.5-0.1,0.7-0.3c0.3-0.2,1-1.9,1-2.3c0-0.3-0.1-0.5-0.3-0.7L36.2,39l2.8-2.8l1.7,1.7c0.2,0.2,0.4,0.3,0.7,0.3
			  c0.3,0,0.4-0.1,0.6-0.2s1-0.4,1.2-0.5c0.5-0.1,0.8-0.5,0.8-1V34h4v2.5c0,0.3,0.1,0.5,0.3,0.7c0.2,0.3,1.9,1,2.3,1
			  c0.3,0,0.5-0.1,0.7-0.3l1.7-1.7l2.8,2.8l-1.7,1.7c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.4,0.2,0.6s0.4,1,0.5,1.2
			  c0.1,0.5,0.5,0.8,1,0.8H58V48z" />
                            <path d="M46,42c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S48.2,42,46,42z M46,48c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S47.1,48,46,48z
			  " />
                            <path d="M24.9,25.5l-4-8c-0.3-0.7-1.5-0.7-1.8,0l-4,8c-0.2,0.2-0.2,0.5-0.1,0.8V60h2V27h2v33h2V27h2v33h2V26.1
			  C25,25.9,25,25.7,24.9,25.5z M17.6,25l2.4-4.8l2.4,4.8H17.6z" />
                            <path d="M11,13H1c-0.6,0-1,0.4-1,1v46h2v-3h5v-2H2v-7h5v-2H2v-8h5v-2H2v-8h5v-2H2v-6h5v-2H2v-3h8v45h2V14C12,13.4,11.6,13,11,13z" />
                            <rect x={5} y={5} width={12} height={2} />
                            <rect x={19} y={5} width={4} height={2} />
                            <rect x={13} y={9} width={10} height={2} />
                            <rect x={35} y={17} width={20} height={2} />
                            <rect x={29} y={22} width={18} height={2} />
                            <rect x={29} y={27} width={26} height={2} />
                            <rect x={29} y={31} width={6} height={2} />
                            <rect x={49} y={22} width={6} height={2} />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>2. Agile Development</h6>
                      <p>We follow Agile Development process that helps us to deliver the project with utmost quality and solid product.</p>
                    </div>
                  </div>
                  <div className="key-block shadow-box block-seven red-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.12 512.12" style={{ enableBackground: 'new 0 0 512.12 512.12' }} xmlSpace="preserve" height="40px" width="40px" fill="#dc2d3c">
                        <g transform="translate(0 -1)">
                          <g>
                            <g>
                              <path d="M506.854,236.029c-5.75-7.925-15.316-12.148-25.046-11.059c-9.73,1.09-18.125,7.324-21.98,16.325l-7.498,17.493
			   c-5.499-88.116-56.39-132.304-79.21-147.79c18.743-18.149,24.594-45.859,14.789-70.036
			   c-9.806-24.177-33.306-39.983-59.396-39.948c-26.09,0.035-49.548,15.902-59.29,40.105c-9.742,24.203-3.817,51.897,14.974,69.997
			   c-10.143,6.906-19.493,14.911-27.88,23.867c-8.468-9.045-17.918-17.115-28.177-24.063c18.717-18.184,24.522-45.91,14.67-70.075
			   C232.958,16.68,209.423,0.915,183.327,1c-26.096,0.086-49.527,16.005-59.22,40.234c-9.693,24.229-3.707,51.917,15.13,69.978
			   c-23.071,15.745-73.849,60.253-78.955,148.715l-7.983-18.628c-3.853-9.004-12.247-15.242-21.98-16.334
			   c-9.732-1.092-19.3,3.132-25.052,11.058c-5.752,7.926-6.799,18.333-2.743,27.246l33.442,73.571
			   c4.515,9.957,10.792,19.017,18.529,26.742l52.595,52.595c-2.965,3.139-4.62,7.291-4.629,11.609v68.267
			   c0.009,9.422,7.645,17.057,17.067,17.067h93.867c9.422-0.009,17.057-7.645,17.067-17.067V436.32h51.2v59.733
			   c0.009,9.422,7.645,17.057,17.067,17.067h93.867c9.422-0.009,17.057-7.645,17.067-17.067v-68.267
			   c-0.009-4.318-1.664-8.47-4.629-11.609l52.595-52.595c7.737-7.725,14.014-16.784,18.529-26.742l33.442-73.571
			   C513.653,254.357,512.604,243.953,506.854,236.029z M281.661,65.12c0-25.921,21.013-46.933,46.933-46.933
			   c25.921,0,46.933,21.013,46.933,46.933s-21.013,46.933-46.933,46.933C302.685,112.024,281.69,91.028,281.661,65.12z
			   M299.128,121.874c9.093,4.759,19.203,7.245,29.466,7.245c10.393,0.004,20.628-2.541,29.81-7.411
			   c16.272,9.35,80.22,53.014,77.273,159.46c-10.528-4.585-22.53-4.36-32.879,0.616c-0.305-33.04-6.899-65.72-19.428-96.294
			   c-1.198-2.807-3.803-4.761-6.834-5.124c-3.03-0.363-6.024,0.921-7.85,3.366c-1.826,2.445-2.207,5.68-1,8.483
			   c0.24,0.562,23.332,55.357,16.912,102.805c-11.605,9.719-24.675,17.542-38.724,23.179c-1.697,0.566-3.313,1.365-4.951,2.101
			   c-3.28-49.299-23.858-76.135-36.425-88.093c11.143-15.411,14.258-35.216,8.384-53.303c-5.875-18.087-20.033-32.282-38.105-38.204
			   C282.188,133.561,290.353,127.249,299.128,121.874z M256.061,154.72c23.564,0,42.667,19.102,42.667,42.667
			   s-19.103,42.667-42.667,42.667s-42.667-19.103-42.667-42.667C213.422,173.834,232.508,154.747,256.061,154.72z M136.594,65.12
			   c0-25.921,21.013-46.933,46.933-46.933s46.933,21.013,46.933,46.933s-21.013,46.933-46.933,46.933
			   C157.619,112.024,136.623,91.028,136.594,65.12z M154.018,121.854c18.567,9.73,40.735,9.686,59.263-0.118
			   c8.804,5.37,16.997,11.684,24.433,18.829c-18.176,5.829-32.459,20.013-38.414,38.148s-2.86,38.026,8.324,53.494
			   c-12.567,11.957-33.144,38.795-36.425,88.094c-1.639-0.736-3.257-1.535-4.955-2.102c-14.047-5.638-27.115-13.46-38.72-23.179
			   c-6.398-47.342,16.676-102.247,16.916-102.809c1.834-4.327-0.176-9.323-4.496-11.174s-9.323,0.14-11.191,4.453
			   c-12.529,30.575-19.123,63.255-19.428,96.296c-10.18-4.86-21.959-5.105-32.332-0.672
			   C74.081,175.235,137.36,131.495,154.018,121.854z M66.561,351.515c-6.287-6.28-11.389-13.642-15.063-21.733l-33.442-73.571
			   c-1.459-3.135-1.191-6.802,0.708-9.692h0c1.843-2.923,5.069-4.683,8.525-4.65c4.059-0.003,7.727,2.417,9.321,6.15l19.083,44.525
			   c5.075,11.874,12.381,22.665,21.521,31.787l38.983,38.983c2.155,2.156,5.297,2.997,8.242,2.208
			   c2.944-0.789,5.244-3.089,6.033-6.033c0.789-2.945-0.053-6.086-2.208-8.242l-38.983-38.983
			   c-3.917-3.914-7.44-8.205-10.517-12.809c8.188-6.321,19.661-6.133,27.638,0.454c2.45,2.061,4.849,4.017,7.218,5.911
			   c0.363,0.348,0.758,0.662,1.178,0.939c13.712,11.699,29.275,21.036,46.05,27.63c8.171,2.971,15.3,8.257,20.517,15.212
			   c9.95,12.957,15.223,28.899,14.963,45.233v15.883h-70.562L66.561,351.515z M119.527,496.053v-68.267h93.867l0.012,68.267H119.527
			   z M284.034,419.253h-55.947c-3.024-5.262-8.624-8.514-14.693-8.533v-15.883c0-2.704-0.12-5.386-0.338-8.045
			   c0.171-0.544,0.284-1.104,0.338-1.672c0-0.512,0.271-51.471,23.658-79.913c1.943-2.354,2.481-5.567,1.41-8.426
			   c-1.071-2.859-3.587-4.928-6.598-5.426c-3.012-0.499-6.06,0.649-7.995,3.01c-11.808,15.697-19.761,33.949-23.219,53.285
			   c-1.792-3.03-3.784-5.939-5.96-8.706c-2.105-2.623-4.384-5.1-6.823-7.415c0.645-50.714,21.045-76.883,31.616-87.022
			   c21.486,16.817,51.67,16.817,73.156,0c10.571,10.139,30.971,36.308,31.616,87.022c-2.439,2.315-4.719,4.793-6.823,7.415
			   c-2.177,2.767-4.168,5.675-5.96,8.706c-3.458-19.336-11.411-37.588-23.219-53.285c-2.996-3.631-8.367-4.15-12.003-1.159
			   c-3.636,2.99-4.164,8.36-1.18,12.001c23.313,28.35,23.659,79.408,23.659,79.921c0.055,0.564,0.168,1.121,0.338,1.661
			   c-0.218,2.659-0.338,5.343-0.338,8.047v15.884C292.658,410.739,287.058,413.991,284.034,419.253z M392.594,496.053h-93.867
			   v-68.267h93.867V496.053z M494.064,256.211l-33.442,73.571c-3.673,8.091-8.776,15.453-15.063,21.733l-59.204,59.204h-70.562
			   v-15.883c-0.261-16.334,5.013-32.276,14.963-45.233c5.216-6.954,12.344-12.24,20.513-15.212
			   c16.779-6.593,32.345-15.932,46.059-27.634c0.416-0.275,0.807-0.586,1.168-0.931c2.371-1.894,4.771-3.852,7.222-5.915
			   c7.973-6.625,19.47-6.846,27.692-0.533c-3.09,4.634-6.631,8.951-10.571,12.887l-38.983,38.983
			   c-2.156,2.155-2.997,5.297-2.208,8.242c0.789,2.944,3.089,5.244,6.033,6.033c2.944,0.789,6.086-0.053,8.242-2.208l38.983-38.983
			   c9.14-9.122,16.446-19.913,21.521-31.787l19.087-44.529c1.593-3.731,5.26-6.15,9.317-6.146c3.448,0.001,6.66,1.754,8.527,4.653
			   S495.491,253.072,494.064,256.211z" />
                              <path d="M196.327,453.386h-8.533c-4.713,0-8.533,3.821-8.533,8.533s3.82,8.533,8.533,8.533h8.533c4.713,0,8.533-3.82,8.533-8.533
			   S201.04,453.386,196.327,453.386z" />
                              <path d="M315.794,470.453h8.533c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533h-8.533
			   c-4.713,0-8.533,3.821-8.533,8.533S311.081,470.453,315.794,470.453z" />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>4. Excellent Support</h6>
                      <p>We are always there to assist our clients in every possible manner at each project phase. Our technical team works hard to ensure that the final product meets clients' expectations.</p>
                    </div>
                  </div>
                  <div className="key-block shadow-box block-seven pink-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve" width="40px" height="40px" fill="#cb1ac5">
                        <g>
                          <g>
                            <g>
                              <path d="M494.933,285.867H486.4V243.2c-0.061-51.816-42.051-93.806-93.867-93.867V81.067c0-39.253-98.731-59.733-196.267-59.733
			   S0,41.813,0,81.067v68.267c0,13.193,10.761,24.619,31.872,34.133C10.761,192.981,0,204.416,0,217.6v68.267
			   C0,299.059,10.761,310.485,31.872,320C10.761,329.515,0,340.949,0,354.133V422.4c0,39.253,98.731,59.733,196.267,59.733
			   c32.215,0.124,64.389-2.301,96.222-7.253c10.972,10.147,25.367,15.784,40.311,15.787h119.467
			   c32.974-0.038,59.696-26.759,59.733-59.733v-128C512,293.508,504.359,285.867,494.933,285.867z M392.533,166.4
			   c42.415,0,76.8,34.385,76.8,76.8v42.667h-153.6V243.2C315.733,200.785,350.118,166.4,392.533,166.4z M196.267,38.4
			   c109.389,0,179.2,25.267,179.2,42.667s-69.811,42.667-179.2,42.667s-179.2-25.267-179.2-42.667S86.878,38.4,196.267,38.4z
			   M17.067,149.333v-42.317c32.427,22.263,106.129,33.783,179.2,33.783s146.773-11.52,179.2-33.783v42.317
			   c-0.031,0.582-0.143,1.156-0.333,1.707c-18.85,3.565-36.152,12.828-49.57,26.539c-42.329,10.18-85.765,15.025-129.297,14.421
			   c-46.347,0.805-92.582-4.815-137.387-16.7C28.015,165.931,17.067,155.46,17.067,149.333z M56.653,192.375
			   c45.58,11.791,92.538,17.405,139.614,16.691c38.133,0.266,76.201-3.209,113.656-10.377c-7.405,13.666-11.275,28.967-11.255,44.51
			   v8.602c-33.817,5.838-68.084,8.671-102.4,8.465c-109.389,0-179.2-25.267-179.2-42.667
			   C17.067,211.627,27.605,201.549,56.653,192.375z M17.067,285.867V243.55c32.427,22.263,106.129,33.783,179.2,33.783
			   c34.304,0.184,68.561-2.553,102.4-8.183v16.717h-8.533c-9.426,0-17.067,7.641-17.067,17.067v20.992
			   c-25.484,3.074-51.131,4.613-76.8,4.608c-46.347,0.805-92.582-4.815-137.387-16.7C28.015,302.464,17.067,291.994,17.067,285.867z
			   M56.653,328.909c45.58,11.791,92.538,17.405,139.614,16.691c25.666,0.023,51.311-1.473,76.8-4.48v51.072
			   c-25.484,3.074-51.131,4.613-76.8,4.608c-109.389,0-179.2-25.267-179.2-42.667C17.067,348.16,27.605,338.082,56.653,328.909z
			   M196.267,465.067c-109.389,0-179.2-25.267-179.2-42.667v-42.317c32.427,22.263,106.129,33.784,179.2,33.784
			   c25.666,0.023,51.311-1.473,76.8-4.48v21.547c-0.011,9.952,2.48,19.747,7.245,28.484
			   C252.46,463.237,224.378,465.124,196.267,465.067z M494.933,320h-25.6c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533
			   h25.6v17.067h-25.6c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h25.6v17.067h-25.6c-4.713,0-8.533,3.821-8.533,8.533
			   c0,4.713,3.82,8.533,8.533,8.533h25.6V422.4h-25.6c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h24.747
			   c-4.083,19.852-21.546,34.108-41.813,34.133H332.8c-20.267-0.026-37.731-14.281-41.813-34.133h24.747
			   c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533h-25.6v-17.067h25.6c4.713,0,8.533-3.821,8.533-8.533
			   c0-4.713-3.82-8.533-8.533-8.533h-25.6V371.2h25.6c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533h-25.6v-17.067h25.6
			   c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533h-25.6v-17.067h204.8V320z" />
                              <path d="M384,395.23v18.637c0,4.713,3.821,8.533,8.533,8.533c4.713,0,8.533-3.82,8.533-8.533V395.23
			   c11.747-4.137,18.796-16.144,16.686-28.418c-2.11-12.274-12.765-21.236-25.219-21.212c-12.454-0.024-23.109,8.939-25.219,21.212
			   C365.204,379.086,372.253,391.092,384,395.23z M392.533,362.667c4.713,0,8.533,3.82,8.533,8.533s-3.821,8.533-8.533,8.533
			   c-4.713,0-8.533-3.82-8.533-8.533S387.821,362.667,392.533,362.667z" />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>6. Data Protection</h6>
                      <p>By signing NDA, our company ensures the utmost safety of all your private data. We deliver best-rated products to our fortune clients by maintaining confidentiality.</p>
                    </div>
                  </div>
                  <div className="key-block shadow-box block-seven green-blue-border" data-aos="fade-up">
                    <div className="key-icon">
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 469.333 469.333" style={{ enableBackground: 'new 0 0 469.333 469.333' }} xmlSpace="preserve" width="40px" height="40px" fill="#0caec1">
                        <g>
                          <g>
                            <g>
                              <path d="M224,64c14.854,0,22.792,1.615,26.583,5.406C254.375,73.208,256,81.156,256,96c0,5.896,4.771,10.667,10.667,10.667
			   s10.667-4.771,10.667-10.667c0-14.844,1.625-22.792,5.417-26.594C286.542,65.615,294.479,64,309.333,64
			   C315.229,64,320,59.229,320,53.333c0-5.896-4.771-10.667-10.667-10.667c-14.854,0-22.792-1.615-26.583-5.406
			   c-3.792-3.802-5.417-11.75-5.417-26.594C277.333,4.771,272.562,0,266.667,0S256,4.771,256,10.667
			   c0,14.844-1.625,22.792-5.417,26.594c-3.792,3.792-11.729,5.406-26.583,5.406c-5.896,0-10.667,4.771-10.667,10.667
			   C213.333,59.229,218.104,64,224,64z M265.667,52.344c0.333-0.344,0.667-0.688,1-1.042c0.313,0.354,0.646,0.698,1,1.042
			   c0.333,0.333,0.688,0.667,1.021,0.99c-0.333,0.323-0.688,0.646-1.021,0.99c-0.333,0.344-0.667,0.688-1,1.042
			   c-0.313-0.354-0.646-0.698-1-1.042c-0.334-0.333-0.688-0.667-1.021-0.99C264.979,53.01,265.333,52.687,265.667,52.344z" />
                              <path d="M456.833,396.5L189.978,129.634C199.6,128.562,210.684,128,224,128c5.896,0,10.667-4.771,10.667-10.667
			   c0-5.896-4.771-10.667-10.667-10.667c-42.875,0-64.688-5.104-77.792-18.208C133.104,75.344,128,53.531,128,10.667
			   C128,4.771,123.229,0,117.333,0c-5.896,0-10.667,4.771-10.667,10.667c0,42.865-5.104,64.677-18.208,77.792
			   c-13.104,13.104-34.917,18.208-77.792,18.208C4.771,106.667,0,111.437,0,117.333C0,123.229,4.771,128,10.667,128
			   c42.875,0,64.688,5.104,77.792,18.208c13.104,13.115,18.208,34.927,18.208,77.792c0,5.896,4.771,10.667,10.667,10.667
			   c5.896,0,10.667-4.771,10.667-10.667c0-13.32,0.563-24.406,1.635-34.031L396.5,456.833c8.063,8.063,18.771,12.5,30.167,12.5
			   c23.521,0,42.667-19.135,42.667-42.667C469.333,415.271,464.896,404.563,456.833,396.5z M117.333,151.375
			   c-3.458-7.646-7.958-14.406-13.792-20.25c-5.833-5.833-12.583-10.344-20.25-13.792c7.667-3.448,14.417-7.958,20.25-13.792
			   c5.833-5.844,10.333-12.604,13.792-20.25c3.458,7.646,7.958,14.406,13.792,20.25c5.833,5.833,12.583,10.344,20.25,13.792
			   c-7.667,3.448-14.417,7.958-20.25,13.792C125.292,136.969,120.792,143.729,117.333,151.375z M134.816,164.983
			   c2.805-7.858,6.544-13.923,11.392-18.775c4.85-4.85,10.917-8.591,18.78-11.397l108.889,108.895l-30.169,30.169L134.816,164.983z
			   M426.667,448c-5.688,0-11.042-2.219-15.083-6.25L258.792,288.958l30.169-30.169L441.75,411.583
			   c4.021,4.031,6.25,9.385,6.25,15.083C448,438.427,438.438,448,426.667,448z" />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="key-details">
                      <h6>8. Enhancement</h6>
                      <p>During Discovery, Designing and Development phases, our technical team always there to provide suggestions and edits that improvise their product in the best possible manner.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default KeyFeature