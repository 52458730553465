import React from 'react'
// import Logo from '../../images/footer-logo.png';
import { NavLink } from 'react-router-dom';
const FooterMap = () => {
    return (
        <>
            <section className="where">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 logo align-items-center">
                            {/* <img src={Logo} alt='Footer Logo' /> */}
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-6 text-white">
                                    <div className="office">
                                        <address>
                                            <h5>Address</h5>
                                            <hr />
                                            <p>Nangal near lotwara District Dausa <br /> Rajasthan - 303509</p>
                                        </address>
                                    </div>
                                </div>
                                <div className="col-md-6 text-white bg-dark">
                                    <div className="office">
                                        <address>
                                            <h5>Follow Us On</h5>
                                            <hr />
                                            <ul className="social-icon-list mt-4">
                                                <li>
                                                    <NavLink className="social-icon faa-facbook" to={`/#`} target="_blank"><i className="fa fa-facebook" /></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="social-icon faa-twitter" to={`/#`} target="_blank"><i className="fa fa-twitter" /></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="social-icon faa-google" to={`/#`} target="_blank"><i className="fa fa-instagram" /></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="social-icon faa-linkedin" to={`/#`} target="_blank"><i className="fa fa-linkedin" /></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="social-icon faa-pinterest" to={`/#`} target="_blank"><i className="fa fa-pinterest" /></NavLink>
                                                </li>
                                            </ul>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* START MAP */}
            <div className="map-container">

            <iframe title='Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28438.169124935226!2d76.73702245483508!3d27.00578844559328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39726bdd9a78a4bd%3A0x6da0bc0072c4cfad!2sNangal%2C%20Rajasthan%20303509!5e0!3m2!1sen!2sin!4v1699761451011!5m2!1sen!2sin" width="100%" height={150} style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>{/* END MAP */}

            
        </>
    )
}

export default FooterMap