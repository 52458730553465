import React from 'react'
import M1 from '../../images/vision-steps1.png'
import M2 from '../../images/vision-steps2.png'
import M3 from '../../images/vision-steps3.png'
const Mission = () => {
    return (
        <>
            <div className="your-business mission">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-up">
                            <div className="topic">Mission &amp; Vision </div>
                        </div>
                        <div className="col-md-8 mb-3 aos-init aos-animate" data-aos="fade-left">
                            <div className="mission-section">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src={M1} alt='Deliver Innovative'/>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="title">Deliver Innovative Web and Mobile Solutions</div>
                                        <p>
                                            To create highly innovative and business-centered web and mobile applications by utilizing the latest technologies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3 aos-init aos-animate" data-aos="fade-right">
                            <div className="mission-section">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src={M2} alt='Ensure Complete' />
                                    </div>
                                    <div className="col-md-10">
                                        <div className="title">Ensure Complete Transparency</div>
                                        <p>
                                            To ensure complete transparency in the procedure and build trust by providing regular updates on the project.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3 aos-init aos-animate" data-aos="fade-left">
                            <div className="mission-section">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src={M3} alt='Equal Priority'/>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="title">Equal Priority To All</div>
                                        <p>
                                            Our platform balances and chooses best delivery routes across vast network to improve bulk email delivery
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Mission