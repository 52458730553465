import React from 'react'

const SmmMain = () => {
    return (
        <>
            <div className="app-development">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-3 aos-init aos-animate" data-aos="fade-right">
                            <div className="app-development-title">
                                Noble Webtech – The Top SMM Company in India
                            </div>
                            <p>Social Media Optimization (SMM) is said to be a process of restructuring a website in a way that it helps the visitors to share and spread the content on the site across various channels of social media. It is the time of P2P – People -to-People. B2B and B2C has become the past. Your hunt for the Top SMM Company in India ends at Markup Designs Pvt. Ltd. specializing in the digital marketing services.</p>
                            <p>We have expertise in the proven Social Media Success Mantras. We help you gain the maximum benefit of Social Media Networks by giving you the benefit of social media marketing optimization services. It is highly necessary to go with a professional company and with a team of experts having the confidence of exceeding the expectation. We at Markup Designs believe in the professional and the result-oriented services only.</p>
                            <p>The objective of SMM is to provide exposure to your brand and increase your customer base. It focuses more on using social networking sites to develop interest, generate sales and improve interaction with a company or its products and services. Social Media Optimization is fundamentally used model that improves the presence of your company or brand in the market. If you are looking for the Top Social Media Company in Delhi, your search will come to halt at Markup Designs.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SmmMain