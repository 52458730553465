import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import IosBanner from './IosBanner'
import IosMain from './IosMain'
import IosComming from './IosComming'
import IosServices from './IosServices'
import ProcessWeFollow from '../ProcessWeFollow'

const IosDevelopment = () => {
  return (
    <>
        <Header></Header>
        <IosBanner></IosBanner>
        <IosMain></IosMain>
        <IosServices></IosServices>
        <IosComming></IosComming>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default IosDevelopment