import React from 'react'

const Facts = () => {
    return (
        <>
            <section className="section p-t80-b60 data-counter" id="counter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 m-b-30">
                            <div className="counter-content text-center">
                                <div><span className="counter-value text-white counter-count" data-count="3000">3000</span><small className="counter-value text-white">+</small></div>
                                <span className="counter-name text-white">Apps Developed</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 m-b-30">
                            <div className="counter-content text-center">
                                <div><span className="counter-value text-white counter-count" data-count="1400">1400</span><small className="counter-value text-white">+</small></div>
                                <span className="counter-name text-white">Website designed</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 m-b-30">
                            <div className="counter-content text-center">
                                <div><span className="counter-value text-white counter-count" data-count="2200">2200</span><small className="counter-value text-white">+</small></div>
                                <span className="counter-name text-white">Happy Clients</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 m-b-30">
                            <div className="counter-content text-center">
                                <div><span className="counter-value text-white counter-count" data-count="300">300</span><small className="counter-value text-white">+</small></div>
                                <span className="counter-name text-white">App Developers</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Facts