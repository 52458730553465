import React from 'react'

const AboutBanner = () => {
    return (
        <>
            <div className="about-us-banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-5">
                            <div className="email-services-inner">
                                <h2 data-animation="animated zoomInLeft">NOBLE WEBTECH IS THE BEST WEB DEVELOPED COMPANY</h2>
                                <h5>Noble Webtech is the Best Website design development and digital marketing company. We have high experience and creative designers who work closely with clients to develop a clearly defined proposition and then formulate a clearly and concise strategy best suited for their brands. We build your profile and credibility in the market to help your business reach its maximum potential</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutBanner