import React from 'react'
import Chosse1 from '../../../images/choose-us-1.png'
import Chosse2 from '../../../images/choose-us-2.png'
import Chosse3 from '../../../images/choose-us-7.png'

const EmarketingServices = () => {
    return (
        <>
            <div className="choose-postmen">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-5 aos-init aos-animate" data-aos="fade-right">
                            <p>Best Email Marketing Service</p>
                            <div className="choose-postmen-title">
                                WHY <span>CHOOSE Noble Webtech</span>
                            </div>
                        </div>
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="why-choose-us-block">
                                <img src={Chosse1} alt='Chosse1' className="mb-4" />
                                <h5>Better Inbox Delivery</h5>
                                <p>Delivery routing and reputation monitoring ensures bulk emails lands in inbox</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="why-choose-us-block aos-init aos-animate" data-aos="fade-left">
                                <img src={Chosse2} alt='Chosse2' className="mb-4" />
                                <h5>Better Service Reliability</h5>
                                <p>Experienced team &amp; infrastructure makes it a reliable email marketing service</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="why-choose-us-block aos-init aos-animate" data-aos="fade-left">
                                <img src={Chosse3} alt='Chosse3' className="mb-4" />
                                <h5>Better Value for Money</h5>
                                <p>Competitive pricing suitable for regular and large bulk email senders</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmarketingServices