import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import EmarketingBanner from './EmarketingBanner'
import EmarketingMain from './EmarketingMain'
import EmarketingServices from './EmarketingServices'
import ProcessWeFollow from '../ProcessWeFollow'
import WhyEmarketing from './WhyEmarketing'

const EmarketingDevelopment = () => {
  return (
    <>  
        
        <Header></Header>
        <EmarketingBanner></EmarketingBanner>
        <EmarketingMain></EmarketingMain>
        <EmarketingServices></EmarketingServices>
        <WhyEmarketing></WhyEmarketing>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default EmarketingDevelopment