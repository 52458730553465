import React from 'react'
import Office from '../../../images/office-icon.png'
import Ses from '../../../images/amazon-ses.png'
import Zimbra from '../../../images/zimbra.png'
import Exchange from '../../../images/exchange-server.png'

const EmarketingBanner = () => {
    return (
        <>
            <div className="email-services-banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="col-md-12 text-center mb-5">
                            <div className="email-services-inner">
                                <h2>Email Marketing Service</h2>
                                <h5>We create responsive web applications and sites leveraging the most robust technologies in the industry to help you boost your business</h5>
                                <div className="email-services-inner-icon-tab">
                                    <div className="icon-bg">

                                        <img src={Office} alt='Office' />
                                    </div>
                                    <div className="icon-bg">
                                        <img src={Ses} alt='Ses' />
                                    </div>
                                    <div className="icon-bg">
                                        <img src={Zimbra} alt='Zimbra' />
                                    </div>
                                    <div className="icon-bg">
                                        <img src={Exchange} alt='Exchange' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmarketingBanner