import React from 'react'

const WebServices = () => {
  return (
    <>
        <div className="your-business">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="topic"> Website Development Features </div>
                            <div className="sub-topic">Helping startups and businesses build mobile solutions that support their brand identity, meet market needs and encourage business growth and expansion.  </div>
                        </div>
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-7 aos-init aos-animate" data-aos="fade-right">
                            <div className="tab-container">
                                <div className='row'>
                                    <h4>Our relevant services include the following:</h4>
                                    <ul className="pl-4">
										<li>Custom Web Development</li>
										<li>Enterprise Web Development</li>
										<li>E learning and educational</li>
										<li>Web CMS Development</li>
										<li>Web Portal Development</li>
										<li>Ecommerce Web Development</li>
										<li>Online booking and ticketing</li>
										<li>Social Networking Websites</li>
									</ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default WebServices