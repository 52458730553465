import React from 'react'
import { NavLink } from 'react-router-dom'
import AndroidIcon from '../../images/android-icon.png'
import IosIcon from '../../images/ios-icon.png'
import WebDev from '../../images/web-development-icon.png'
import WebDesign from '../../images/web-design.png'
import Seo from '../../images/seo-icon.png'
import Sms from '../../images/smm-icon.png'
import EmailMarketing from '../../images/email-marketing.png'
import ContentMarketing from '../../images/content-marketing.png'

const OurServices = () => {
    return (
        <>
            <ul className="service-tab py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <NavLink to={`/android-app-development`}>
                                <img src={AndroidIcon} alt='AndroidIcon' />
                                <h5>Android App Development</h5>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to={`/iso-app-development`}>
                                <img src={IosIcon} alt='IosIcon' />
                                <h5>iSO App Development</h5>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to={`/php-development`}>
                                <img src={WebDev} alt='WebDev' />
                                <h5>Php Web Development</h5>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to={`/web-development`}>
                                <img src={WebDesign} alt='WebDesign' />
                                <h5>Website  Development</h5>
                            </NavLink>                            
                        </div>
                        <div className="col-md-3">
                            <NavLink to={`/seo`}>
                                <img src={Seo} alt='Seo' />
                                <h5>Search Engine Optimisation</h5>
                            </NavLink>                            
                        </div>
                        <div className="col-md-3">
                            <NavLink to={`/social-media-marketing`}>
                            <img src={Sms} alt='Sms' />
                                <h5>Social Media Marketing</h5>
                            </NavLink>
                        </div>
                        <div className="col-md-3">
                            <NavLink to={`/email-marketing`}>
                                <img src={EmailMarketing} alt='EmailMarketing' />
                                <h5>E-mail Marketing</h5>
                            </NavLink>                           
                        </div>
                        <div className="col-md-3">
                            <NavLink to={`/content-marketing`}>
                                <img src={ContentMarketing} alt='ContentMarketing' />
                                <h5>Content Writing</h5>
                            </NavLink>                            
                        </div>
                    </div>
                </div>
            </ul>

        </>
    )
}

export default OurServices