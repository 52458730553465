import React from 'react'
import F1 from '../../../images/included-feature-4.png'
import F2 from '../../../images/included-feature-1.png'
import F3 from '../../../images/included-feature-2.png'
import F4 from '../../../images/included-feature-5.png'
import F5 from '../../../images/included-feature-3.png'
import F6 from '../../../images/included-feature-6.png'

const WhyEmarketing = () => {
    return (
        <>
            <div className="why-postmen bg-dark">
                <div className="container">
                    <div className="row mb10">
                        <div className="col-md-12 text-center mb-5 aos-init aos-animate" data-aos="fade-right">
                            <div className="sub-title">Preferred Top Email Marketing Services</div>
                            <div className="title">
                                Why Noble Webtech
                            </div>
                        </div>
                        <div className="col-md-6 aos-init aos-animate" data-aos="fade-left">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={F1} alt='Delivery Platform' />
                                </div>
                                <div className="col-md-10">
                                    <div className="title pt-2 pb-3">Pro Delivery Platform</div>
                                    <p>
                                        Our platform balances and chooses best delivery routes across vast network to improve bulk email delivery
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 aos-init aos-animate" data-aos="fade-left">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={F2} alt='Dedicated IPs' />
                                </div>
                                <div className="col-md-10">
                                    <div className="title pt-2 pb-3">Dedicated IPs *</div>
                                    <p>
                                        Dedicated IP may be assigned to you that helps build sender reputation improves inbox email delivery
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row pt-5 mb10">
                        <div className="col-md-6 aos-init" data-aos="fade-left">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={F3} alt='Reputation Monitor' />
                                </div>
                                <div className="col-md-10">
                                    <div className="title pt-2 pb-3">Reputation Monitor</div>
                                    <p>
                                        Proactive in-house SMTP monitoring system manages reputation across Real-time Black Lists aka RBL
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 aos-init" data-aos="fade-left">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={F4} alt='Reliable Service Level' />
                                </div>
                                <div className="col-md-10">
                                    <div className="title pt-2 pb-3">Reliable Service Level</div>
                                    <p>
                                        Our network of servers in multiple data center in US, Europe and Asia helps achieve 99.99% service uptime
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row  pt-5 mb10">
                        <div className="col-md-6 aos-init" data-aos="fade-right">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={F5} alt='Feature-rich Tool'/>
                                </div>
                                <div className="col-md-10">
                                    <div className="title pt-2 pb-3">Feature-rich Tool</div>
                                    <p>
                                        Easy to use tool with advanced email marketing features. Get real-time reports on Opens, Link Clicks and Bounces
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 aos-init" data-aos="fade-right">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={F6} alt='Quick Support' />
                                </div>
                                <div className="col-md-10">
                                    <div className="title pt-2 pb-3">Quick Support</div>
                                    <p>
                                        Get support via ticket email and Live chat during business hours
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WhyEmarketing