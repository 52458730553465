import React from 'react'

const ContactBanner = () => {
    return (
        <>
            <div className="contact-us-banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-5">
                            <div className="email-services-inner">
                                <h2>Contact Us</h2>
                                <h5>We would be happy to hear from you, Please fill in the form below or mail us your requirements on</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactBanner