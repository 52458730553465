import React from 'react'

const ContactInfo = () => {
    
    return (
        <>
            <div className="service-items my-5">
                <div className="container">
                    <div className="row pt-5 mb10">
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="topic" />
                            <div className="footer-header">Contact Detail</div>
                            <p>Phone: +91-93589 87232</p>                            
                        </div>
                        
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="" />
                            <p>Address: Nangal near lotwara District Dausa Rajasthan - 303509</p>                            
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactInfo