import React from 'react'
import AndroidBanner from '../../../images/android-banner-pic.png'

const AndriodBanner = () => {
    return (
        <>
            <div className='banner-services'>
                <div className="android-app-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <img src={AndroidBanner} alt='AndroidBanner' />
                            </div>
                            <div className="col-md-7">
                                <div className="android-app">
                                    <h2>Android App Development Services</h2>
                                    <h5>We would be happy to hear from you, Please fill in the form below or mail us your requirements on</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AndriodBanner