import React from 'react'
import Menu from '../../Menu/Menu'
import { NavLink } from 'react-router-dom'
import banpic from '../../../images/bg-slider-scaled-removebg-preview.png'
const Banner = () => {
    return (
        <>
            <Menu></Menu>
            <div className="banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="banner-text">
                                <div className="banner-inner">
                                    <h1 className="pt-4 text-uppercase">NOBLE WEBTECH IS BEST WEB Design COMPANY</h1>
                                    <h5>Noble Webtech is the Best Website design development and digital marketing company.</h5>
                                    <h6 className="mb-3">We have high experience and creative designers who work closely with clients to develop a clearly defined proposition and then formulate a clearly and concise strategy best suited for their brands. </h6>
                                    <p>We build your profile and credibility in the market to help your business reach its maximum potential</p>
                                    <NavLink className="btn-style02 btn-slide" to={'/contact'}> free consultation </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 d-none d-md-block'>
                            <img src={banpic} alt='banner' className='img-fluid'></img>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Banner