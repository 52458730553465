import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import BBanner from '../../../images/eg22.jpg'
const BlogDetails = () => {
  return (
    <>
      <Header />
      <div className='container mt-5 py-5'>
        <div className='row'>
          <div className='col-md-7'>
            <img src={BBanner} alt='b1' className='img-fluid'></img>
            <div className='mt-4 text-justify'>

              <h4>All You need to know about Contract Cheating</h4>
              <p>Imagine you are walking down the magnificent lawns of Harvard, practising for a debate at Yale, ready for a relay at Princeton, or developing an application at the Massachusetts Institute of Technology. The dream looks so surreal that you wish to study there. However, getting a chance to study in the USA isn’t that easy; from planning here to executing there, there is a long process you must go through. But you don’t have to worry much about your steps in your dream of studying abroad because ExpertGRAD professionals help you at each stage.</p>

              <h5>Study Abroad- The Fancy Phrase</h5>
              <p>“I’m going abroad for higher education.” Doesn’t this line sound fancy and fascinating when someone uses this phrase? And when the study destination is the United States, the fanciness multiplies. It isn’t only because the USA is home to 4000 universities and colleges. Still, because of the diverse culture, it has become an excellent place for international students to pursue their higher education. Let’s talk about the quality of education. Also, the USA is one of the best countries as they focus more on practical and extensive knowledge rather than focusing on theoretical sections.</p>

              <p>The exposure students get when they pursue their graduation in any US university helps them enhance their skills and become more efficient. This becomes a plus point when they look for jobs because any course or degree pursued from the United States brings in the quality on the sheets and in your mind. So, if you are planning to achieve a particular dream, go for it with complete planning and with your basics right. To help you plan each step to study in the USA, here is a comprehensive guide of how you should plan your further steps.</p>

              <h5>Keep the Aim Clear</h5>
              <p>Many students struggle to find their ultimate aim in life, but if you have decided what you want to be in life, it is better you walk backwards with your plan. No matter what your ultimate goal is, if you want to achieve every bit of it, then you must plan it back, mentioning all the crucial steps that will be required for you to accomplish to reach the level you wish for. And if you have your aim clear, then start working towards it at the earliest. In this plan, studying from the USA will help you in the best possible manner. So, here’s how you should make your dream of studying in the USA a reality. With the help of these simple steps, you will unfold the difficult process easier and won’t end up messing up anything. So, without further ado, let’s get started.</p>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='blogview'>
              <div className='row'>
                <div className='col-md-5'>
                  <img src={BBanner} alt='b1' className='img-fluid'></img>
                </div>
                <div className='col-md-7'>
                  <h5 className='elep'>Study Abroad- The Fancy Phrase</h5>
                  <p className='elep'>The exposure students get when they pursue their graduation in any US university helps them enhance their skills and become more efficient. </p>
                </div>
              </div>
            </div>{/*--blogview--*/}
            <div className='blogview'>
              <div className='row'>
                <div className='col-md-5'>
                  <img src={BBanner} alt='b1' className='img-fluid'></img>
                </div>
                <div className='col-md-7'>
                  <h5 className='elep'>Study Abroad- The Fancy Phrase</h5>
                  <p className='elep'>The exposure students get when they pursue their graduation in any US university helps them enhance their skills and become more efficient. </p>
                </div>
              </div>
            </div>{/*--blogview--*/}
            <div className='blogview'>
              <div className='row'>
                <div className='col-md-5'>
                  <img src={BBanner} alt='b1' className='img-fluid'></img>
                </div>
                <div className='col-md-7'>
                  <h5 className='elep'>Study Abroad- The Fancy Phrase</h5>
                  <p className='elep'>The exposure students get when they pursue their graduation in any US university helps them enhance their skills and become more efficient. </p>
                </div>
              </div>
            </div>{/*--blogview--*/}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BlogDetails