import React from 'react'
import { NavLink } from 'react-router-dom'
import './Menu.css'
// import Logo from '../../images/logo-main.png'

const Header = () => {
    return (
        <>
            {/* partial:index.partial.html */}
            <div id="main-menu" className="main-menu">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="logo">
                            {/* <NavLink to={'/'}><img src={Logo} alt='logo' /></NavLink> */}
                        </div>
                        <div className="header-right justify-content-end">
                            <div className="header-right-bar">
                                <i className="fa fa-mobile" /> +91-93589 87232
                            </div>
                        </div>

                        <input className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon" />
                        <label for="menu-icon"></label>
                        <nav className="nav">
                            <ul className="pt-5">
                                <li><NavLink to={`/`}>Home</NavLink></li>
                                <li><NavLink to={`/about`}>About</NavLink></li>
                                <li><NavLink to={`/services`}>Service</NavLink></li>
                                <li><NavLink to={`/contact`}>Contact</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header