import React from 'react'
import CM from '../../../images/content-marketing.png'

const CmarketingBanner = () => {
    return (
        <>
            <div className="email-services-banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">

                        <div className="android-app-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={CM} alt='Content Marketing' style={{ width: 'auto' }} className="shake" />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="php-web-app">
                                            <h2>Content Marketing Services</h2>
                                            <h5>We would be happy to hear from you, Please fill in the form below or mail us your requirements on</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CmarketingBanner