import React from 'react'

const CMarketingServices = () => {
    return (
        <>
            <hr></hr>
            <div className="service-items mb-5">
                <div className="container">
                    <div className="row pt-5 mb10">
                        <div className="col-md-12 mb-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="topic">Plagiarism Free Contents at Cost-effective Rates </div>
                        </div>
                        <div className="col-md-12 aos-init" data-aos="fade-up">
                            <p>
                                Today, contents are considered the king of the online industry. It plays a very vital and essential part in the success and failure of a business. So, it is certainly necessary to ensure the availing of the Content Management Services from the very dependable companies like Markup Designs.
                            </p>
                            <p>Developing only good quality contents is not enough. It also need to be plagiarism free and should serve the purpose of Search Engine Optimization. With us you are guaranteed the fulfillment of all the essential terms of the SEO content writing services in India. We value our every single client no matter whether it is a startup or an established company. For the organizations looking for the successful results, we Markup Designs are the best and the one-stop solution not only in Delhi but across the country.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="your-business">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="topic"> Custom Android apps for your business </div>
                            <div className="sub-topic">Helping startups and businesses build mobile solutions that support their brand identity, meet market needs and encourage business growth
                                and expansion. </div>
                        </div>
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-7 aos-init aos-animate" data-aos="fade-right">
                            <div className="tab-container">
                                <div className='row'>
                                    <p>On-demand taxi apps usually consist of two separate in-app roles or apps: Driver’s and Passenger’s. Beside basic functionality, taxi apps require map APIs (Google Maps, MapKit), payment gateways (Stripe, PayPal) and in-app calling feature integration (Twilio, Bandwith).</p>
                                    <div className="col-md-4 pl-0 pr-5">
                                        <h5>2200 hours</h5>
                                        <small>Average development time</small>
                                    </div>
                                    <div className="col-md-4 pl-0 pr-5">
                                        <h5>6 months</h5>
                                        <small>Average project length</small>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <div className="btn"> See Case Study </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CMarketingServices