import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import AndriodBanner from './AndriodBanner'
import AndroidMain from './AndroidMain'
import AndriodServices from './AndriodServices'
import CommingApp from './CommingApp'
import ProcessWeFollow from '../ProcessWeFollow'

const AndroidDevelopment = () => {
  return (
    <>  
        
        <Header></Header>
        <AndriodBanner></AndriodBanner>
        <AndroidMain></AndroidMain>
        <AndriodServices></AndriodServices>
        <CommingApp></CommingApp>
        <ProcessWeFollow></ProcessWeFollow>
        <Footer></Footer>
    </>
  )
}

export default AndroidDevelopment