import React from 'react'
import Email from '../../../images/email-2.jpg'

const EmarketingMain = () => {
    return (
        <>
            <div className="app-cost">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 aos-init aos-animate" data-aos="fade-right">
                            <div className="inner email-mrkting-tool">
                                <h4 className="mb-5">Powerful Email Marketing Tool</h4>
                                <div className="media hosting-info-list">
                                    <div className="d-flex info-icon">
                                        <i className="fa fa-check-circle" aria-hidden="true" />
                                    </div>
                                    <div className="media-body">
                                        <h5 className="xs-title">Fast Email Delivery</h5>
                                        <p>Our tool achieves high delivery speed for your campaigns.</p>
                                    </div>
                                </div>
                                <div className="media hosting-info-list">
                                    <div className="d-flex info-icon">
                                        <i className="fa fa-check-circle" aria-hidden="true" />
                                    </div>
                                    <div className="media-body">
                                        <h5 className="xs-title">Drag and Drop Editor</h5>
                                        <p>Easily designed stunning email campaigns , just Drag and Drop it.</p>
                                    </div>
                                </div>
                                <div className="media hosting-info-list">
                                    <div className="d-flex info-icon">
                                        <i className="fa fa-check-circle" aria-hidden="true" />
                                    </div>
                                    <div className="media-body">
                                        <h5 className="xs-title">Advancecd Email Marketing Features</h5>
                                        <p>List CSV import, Realtime statistics, Data export, Suppression list, Autoresponder etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 aos-init aos-animate" data-aos="fade-left">
                            <img src={Email} alt='Email' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-development pricing">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-5 aos-init aos-animate" data-aos="fade-left">
                            <div className="choose-postmen-title">
                                Email Campaign Pricing <span>Plan &amp; Features</span>
                            </div>
                        </div>
                        <div className="col-md-12 aos-init aos-animate" data-aos="fade-right">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead className="xs-single-pricing">
                                        <tr>
                                            <th>Pay As You Go</th>
                                            <th>Rs. 4,100 <small>0.042 Paisa</small></th>
                                            <th>Rs. 8,100 <small>0.016 Paisa</small></th>
                                            <th>Rs. 12,100 <small>0.0081 Paisa</small></th>
                                            <th>Rs. 16,100 <small>0.0094 Paisa</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>Email Limit</b></td>
                                            <td>1 Lackh</td>
                                            <td>5 Lackh</td>
                                            <td>10Lackh</td>
                                            <td>15Lakh</td>
                                        </tr>
                                        <tr>
                                            <td><b>Daily Limit</b></td>
                                            <td>No limit</td>
                                            <td>30K Daily</td>
                                            <td>40K Daily</td>
                                            <td>50K Daily</td>
                                        </tr>
                                        <tr>
                                            <td><b>Contract</b></td>
                                            <td>Monthly</td>
                                            <td>Monthly</td>
                                            <td>Monthly</td>
                                            <td>Monthly</td>
                                        </tr>
                                        <tr>
                                            <td><b>Delivery Rate</b></td>
                                            <td>99%</td>
                                            <td>99%</td>
                                            <td>99%</td>
                                            <td>99%</td>
                                        </tr>
                                        <tr>
                                            <td><b>Email Template</b></td>
                                            <td>HTML &amp; TEXT</td>
                                            <td>HTML &amp; TEXT</td>
                                            <td>HTML &amp; TEXT</td>
                                            <td>HTML &amp; TEXT</td>
                                        </tr>
                                        <tr>
                                            <td><b>Interface</b></td>
                                            <td>Web based</td>
                                            <td>Web based</td>
                                            <td>Web based</td>
                                            <td>Web based</td>
                                        </tr>
                                        <tr>
                                            <td><b>Mail Scheduling</b></td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><b>Statistics Report</b></td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><b>Mailing List</b></td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><b>24*7 Support</b></td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><b>SPF, DKIM, DMARC, rDNS, MX</b></td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><b>SMTP ACCESS</b></td>
                                            <td>No</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><b>Dedicated IP’s</b></td>
                                            <td>No</td>
                                            <td>2 IP</td>
                                            <td>5 IP</td>
                                            <td>10 IP</td>
                                        </tr>
                                        <tr>
                                            <td><b>Dedicated Server</b></td>
                                            <td>No</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td><b>SMTP Relay</b></td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>5 Relay</td>
                                            <td>10 Relay</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default EmarketingMain