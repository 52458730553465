import React from 'react'

const AboutMain = () => {
    return (
        <>
            <div className="app-development">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-3">
                            <div className="app-development-title aos-init aos-animate" data-aos="fade-left">
                                Noble Webtech – The Top SMM Company in India
                            </div>
                            <p data-aos="zoom-in" className="aos-init aos-animate">At Noble Webtech, innovation is not just a jargon; rather it is a core element of our organizational professionalism. A venture, which was pioneered in the year 2017, continues with disciplined professional values and keeps on ascending with new achievements. We always look forward to taking our organizational objectives ahead in a manner that ushers in creating value-based relationships with our clients. The journey started with five dedicated professionals, and currently, after 2.5 years.</p>
                            <p data-aos="zoom-in" className="aos-init aos-animate">Noble Webtech serves leading enterprises across key industries by providing a range of services such as digital marketing, development, and designing. We believe in the importance of faith, transparency, suppleness and professional values. Our organizational values are based on the ‘Employees First’ attitude, which makes sure that our efforts to meet our clients’ interests are being executed perfectly. And this is the strength that leads us to help our esteemed clients grow their business in the digital age. We keep on taking up new technologies and tools to make our endeavour more productive and effective.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutMain