import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AboutMain from './AboutMain'
import ServiceWeOffer from '../Home/HomeServices/ServiceWeOffer'
import CreateStory from '../Home/CreateStory/CreateStory'
import AboutBanner from './AboutBanner'
import Mission from './Mission'


const About = () => {
  return (
    <>
        <Header></Header>
        <AboutBanner></AboutBanner>
        <AboutMain></AboutMain>
        <Mission></Mission>
        <ServiceWeOffer></ServiceWeOffer>
        <CreateStory></CreateStory>
        <Footer></Footer>
    </>
  )
}

export default About