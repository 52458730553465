import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import ContactBanner from './ContactBanner'
import ContactInfo from './ContactInfo'

const Contact = () => {
  return (
    <>
      <Header></Header>
      <ContactBanner></ContactBanner>
      <ContactInfo></ContactInfo>
      <Footer></Footer>
    </>
  )
}

export default Contact