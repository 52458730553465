import React from 'react'

const IosServices = () => {
    return (
        <>
            <div className="your-business">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 aos-init aos-animate" data-aos="fade-left">
                            <div className="topic"> Custom Android apps for your business </div>
                            <div className="sub-topic">Helping startups and businesses build mobile solutions that support their brand identity, meet market needs and encourage business growth
                                and expansion. </div>
                        </div>
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-7 aos-init aos-animate" data-aos="fade-right">
                            <div className="tab-container">
                                <div className='row'>
                                    <p>On-demand taxi apps usually consist of two separate in-app roles or apps: Driver’s and Passenger’s. Beside basic functionality, taxi apps require map APIs (Google Maps, MapKit), payment gateways (Stripe, PayPal) and in-app calling feature integration (Twilio, Bandwith).</p>
                                    <div className="col-md-4 pl-0 pr-5">
                                        <h5>2200 hours</h5>
                                        <small>Average development time</small>
                                    </div>
                                    <div className="col-md-4 pl-0 pr-5">
                                        <h5>6 months</h5>
                                        <small>Average project length</small>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <div className="btn"> See Case Study </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}

export default IosServices