import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import ServiceBanner from './ServiceBanner'
import OurServices from './OurServices'
const Services = () => {
  return (
    <>          
        <Header></Header>
        <ServiceBanner></ServiceBanner>
        <OurServices></OurServices>
        <Footer></Footer>
    </>
  )
}

export default Services